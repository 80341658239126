<template>
  <div class="content">
      <h4>Available feeds</h4>

      <!-- Feeds -->
      <div class="d-flex flex-column">
        <!-- Search bar -->
        <b-input-group class="mb-3">
          <template #append>
            <b-button size="sm" variant="white" title="Clear text" @click="clearSearchText">
              Clear filter
            </b-button>
          </template>
          <b-form-input
            v-model="searchText"
            placeholder="Filter feeds"
            autocomplete="off"
            trim
            debounce="100"
            autofocus
            @update="filterFeeds"
          ></b-form-input>
        </b-input-group>

        <div v-if="loadingCount > 0">Fetching feeds..</div>
        <div v-else-if="feeds.length === 0">No feeds available.</div>
        <table
          v-else
          class="table table-google table-sm table-hover"
        >
          <thead>
            <th>#</th>
            <th>Title</th>
            <th class="text-right">Items</th>
            <th class="text-nowrap">Last update</th>
            <th class="text-nowrap">Next update</th>
            <th>URL</th>
            <th>Action</th>
          </thead>
          <tbody>
            <tr v-for="feed in filteredFeeds" :key="feed.id">
              <td class="row-fit">{{ feed.meta.index + 1 }}</td>
              <td class="row-fit">{{ feed.title }}</td>
              <td class="text-right row-fit">{{ feed.node_count | format }}</td>
              <td class="row-fit text-nowrap">
                <span v-if="feed.update_ts === 0">Unknown</span>
                <span v-else>{{ feed.update_ts | longdate }} {{ feed.update_ts | time }}</span>
              </td>
              <td class="row-fit text-nowrap">
                <span v-if="feed.next_ts === 0">Unknown</span>
                <span v-else>{{ feed.next_ts | longdate }} {{ feed.next_ts | time }}</span>
              </td>
              <td class="text-nowrap">{{ feed.url }}</td>
              <td class="row-fit text-nowrap with-btn-group">
                <b-button
                  size="sm"
                  variant="primary"
                  title="Copy to clipboard"
                  class="btn-clipboard text-nowrap"
                  @click="toClipboard(feed)"
                >
                  <span v-if="feed.meta.clipboardCopied">Copied!</span>
                  <span v-else>Copy link</span>
                </b-button>

                <router-link
                  :to="{ path: `/feed/${feed.id}`}"
                  tag="a"
                  class="btn btn-white btn-sm ml-2 text-nowrap"
                >
                  Show feed
                </router-link>

                <router-link
                  :to="{ path: `/feed_price/${feed.id}`}"
                  tag="a"
                  class="btn btn-white btn-sm ml-2 text-nowrap"
                  :class="{ 'invisible': !feed.pricing_instructions }"
                >
                  Show price
                </router-link>

                <b-button
                  size="sm"
                  variant="primary"
                  title="Export to spreadsheet"
                  class="btn-white ml-2 text-nowrap"
                  :class="{ 'invisible': !feed.export_instructions }"
                  :disabled="feed.meta.exporting"
                  @click="toExport(feed)"
                >
                  <span v-if="feed.meta.exporting">Exporting..</span>
                  <span v-else>Export</span>
                </b-button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
  </div>
</template>

<script>
export default {
  name: 'Feeds',
  components: {},
  computed: {
    filteredFeeds() {
      if (this.searchText.length === 0) return this.feeds;
      const filteredFeeds = this.feeds.filter((feed) => (
        feed.title.toLowerCase().includes(this.searchText)
      ));
      return filteredFeeds;
    },
  },
  data() {
    return {
      app: this.$store.state.app,
      loadingCount: 0,
      searchText: '',
      feeds: [],
    };
  },
  methods: {
    fetchFeeds() {
      if (!this.app.mandantCode) return;
      this.loadingCount += 1;
      this.feeds = [];
      this.$http
        .get('/feeds')
        .query({ mandantCode: this.app.mandantCode })
        .then((res) => {
          const { feeds } = res.body;
          feeds.forEach((feed, index) => {
            feed.meta = {
              index,
              clipboardCopied: false,
              exporting: false,
            };
          });
          this.feeds = feeds;
          this.filterFeeds();
        })
        .catch((err) => {
          alert(`Failed to fetch feeds: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount -= 1;
        });
    },
    toClipboard(feed) {
      const clipboardField = document.createElement('textarea');
      clipboardField.value = feed.url;
      document.body.appendChild(clipboardField);
      clipboardField.select();
      const textCopied = document.execCommand('copy');
      clipboardField.remove();
      if (textCopied) {
        feed.meta.clipboardCopied = true;
        setTimeout(() => { feed.meta.clipboardCopied = false; }, 1000);
      } else {
        // todo: handle clipboard error?
      }
    },
    toExport(feed) {
      if (feed.meta.exporting) return;
      feed.meta.exporting = true;
      this.$http
        .get(`/feed/${feed.id}/export`)
        .then((res) => {
          const link = document.createElement('a');
          link.href = res.body.url;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch(() => {
          alert('Failed to export feed');
        })
        .finally(() => {
          feed.meta.exporting = false;
        });
    },
    clearSearchText() {
      this.searchText = '';
      this.filterFeeds();
    },
    filterFeeds() {
      // todo: deprecate
    },
  },
  created() {
    this.fetchFeeds();
  },
  watch: {
    'app.mandantCode': {
      handler() {
        this.fetchFeeds();
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-clipboard {
  width: 76px;
}
</style>
